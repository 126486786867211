import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dcpayIos from '../assets/dcpay-ios.png';
import image1 from '../assets/dc-pay-bookstore.png';
import image2 from '../assets/dc-pay-donations.png';
import image3 from '../assets/dc-pay-flowers.jpg';

function LandingPage() {
    const [currentImage, setCurrentImage] = useState(0);
    const images = [image1, image2, image3];

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 5000);
        return () => clearInterval(timer);
    }, []);

    const nextImage = (currentImage + 1) % images.length;
    const prevImage = (currentImage - 1 + images.length) % images.length;

    return (
        <div className="min-h-screen bg-gray-900 text-white">
            <div className="container mx-auto px-4 py-16">
                <h1 className="text-5xl font-bold text-center mb-12">Meet DC Pay</h1>

                <div className="flex justify-center mb-16">
                    <img src={dcpayIos} alt="DC Pay Payment Collection Screen" className="max-w-xs shadow-lg rounded-lg" />
                </div>

                <h2 className="text-3xl font-bold text-center mb-8">The Future of Contactless Payments is Here</h2>

                <p className="text-xl text-center mb-12">
                    Say goodbye to cumbersome card readers and hello to seamless, secure payments with DC Pay—your all-in-one Tap to Pay solution for iPhone and Android.
                </p>

                {/* Carousel */}
                <div className="relative w-full max-w-4xl mx-auto mb-16 overflow-hidden">
                    <div className="flex items-center justify-center">
                        {/* Previous Image - Hidden on mobile */}
                        <div className="hidden md:block w-1/4 opacity-50 transform scale-75">
                            <img
                                src={images[prevImage]}
                                alt="Previous"
                                className="object-contain w-full"
                            />
                        </div>

                        {/* Current Image */}
                        <div className="w-full md:w-1/2 z-10">
                            <img
                                src={images[currentImage]}
                                alt="Current"
                                className="object-contain w-full"
                            />
                        </div>

                        {/* Next Image - Hidden on mobile */}
                        <div className="hidden md:block w-1/4 opacity-50 transform scale-75">
                            <img
                                src={images[nextImage]}
                                alt="Next"
                                className="object-contain w-full"
                            />
                        </div>
                    </div>
                </div>

                {/* Navigation Dots */}
                <div className="flex justify-center space-x-2 mb-8">
                    {images.map((_, index) => (
                        <button
                            key={index}
                            className={`w-3 h-3 rounded-full ${index === currentImage ? 'bg-white' : 'bg-gray-500'
                                }`}
                            onClick={() => setCurrentImage(index)}
                        />
                    ))}
                </div>

                {/* Navigation Arrows */}
                <div className="flex justify-between items-center mt-4">
                    <button
                        onClick={() => setCurrentImage(prevImage)}
                        className="bg-gray-800 text-white p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    >
                        &#8592; {/* Left arrow */}
                    </button>
                    <button
                        onClick={() => setCurrentImage(nextImage)}
                        className="bg-gray-800 text-white p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    >
                        &#8594; {/* Right arrow */}
                    </button>
                </div>

                {/* Features */}

                <div className="grid md:grid-cols-3 gap-8 mb-16">
                    <div className="bg-gray-800 p-6 rounded-lg">
                        <h3 className="text-2xl font-bold mb-4">No Extra Hardware Required</h3>
                        <p>No need for clunky terminals or additional devices. Just use your smartphone and start accepting contactless payments instantly.</p>
                    </div>
                    <div className="bg-gray-800 p-6 rounded-lg">
                        <h3 className="text-2xl font-bold mb-4">Effortless Integration with Your CRM</h3>
                        <p>DC Pay doesn't just handle payments; it connects them directly with your CRM, giving you a full 360-degree view of customer interactions.</p>
                    </div>
                    <div className="bg-gray-800 p-6 rounded-lg">
                        <h3 className="text-2xl font-bold mb-4">Accept Payments on Any Channel</h3>
                        <p>Whether it's in-store, online, or through mobile devices, DC Pay supports Tap to Pay across all channels.</p>
                    </div>
                </div>

                <h2 className="text-3xl font-bold text-center mb-8">Features That Set DC Pay Apart</h2>

                <div className="grid md:grid-cols-3 gap-8 mb-16">
                    <div className="bg-gray-800 p-6 rounded-lg">
                        <h3 className="text-2xl font-bold mb-4">Multi-Wallet Support</h3>
                        <p>Accept payments from traditional debit and credit cards, Apple Pay, Google Pay, and other digital wallets—all with a single tap.</p>
                    </div>
                    <div className="bg-gray-800 p-6 rounded-lg">
                        <h3 className="text-2xl font-bold mb-4">Contactless and Secure</h3>
                        <p>DC Pay ensures secure, contactless transactions that are as fast as they are reliable, making payments simple for both you and your customers.</p>
                    </div>
                    <div className="bg-gray-800 p-6 rounded-lg">
                        <h3 className="text-2xl font-bold mb-4">360° Customer Insights</h3>
                        <p>Access a complete view of every payment and engagement your customers have across channels—empowering your sales, service, and marketing teams with rich, actionable data.</p>
                    </div>
                </div>

                <h2 className="text-3xl font-bold text-center mb-8">See the video to set up Tap to Pay for yourself or your business</h2>
                <div className="flex justify-center mb-16">
                    <iframe
                        width="560"
                        height="315"
                        src="https://drive.google.com/file/d/1_uZoDeSb0aq2sehRYThjGxjOcOHU4KxG/preview"
                        title="DC Pay Introduction"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>

                <div className="text-center mb-8">
                    <h2 className="text-3xl font-bold mb-4">Transform Payments with DC Pay</h2>
                    <p className="text-xl mb-8">Upgrade your payment experience and elevate customer engagement with DC Pay. Simplify operations, gain deeper insights, and drive business growth—all from one intuitive app.</p>
                    <Link to="/signup" className="bg-indigo-500 text-white font-bold py-3 px-6 rounded hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                        Get Started
                    </Link>
                </div>

                <p className="text-center text-gray-400 text-sm">
                    Note: Stripe payment processing charges apply for app usage.
                </p>
            </div>

            <footer className="bg-gray-800 py-4">
                <div className="container mx-auto px-4 text-center">
                    <Link to="/privacy-policy" className="text-gray-400 hover:text-indigo-300 text-sm">
                        Privacy Policy
                    </Link>
                </div>
            </footer>
        </div>
    );
}

export default LandingPage;