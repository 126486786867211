import React, { useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter as Router, Navigate, useLocation, useNavigate, Link } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import PrivacyPolicy from './components/PrivacyPolicy';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import SignUp from './components/SignUp';
import Logout from './components/Logout';
import { isAuthenticated, logout } from './auth';
import ManageSubusers from './components/ManageSubUsers';
import LandingPage from './components/LandingPage';
import axios from 'axios';

function AppContent() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // Initialize isLoggedIn based on the presence of token in localStorage
    return !!localStorage.getItem('token');
  });
  const [username, setUsername] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Set up an interceptor to handle 401 responses globally
    const interceptor = axios.interceptors.response.use(
      (response) => {
        // Check if the response indicates an expired token
        if (response.data && response.data.isValid === false) {
          // handleLogout();
          // navigate('/console');
        }
        return response;
      },
      (error) => {
        if (error.response?.status === 401) {
          // handleLogout();
          // navigate('/console');
          console.log("forbidden");
        }
        return Promise.reject(error);
      }
    );

    // Clean up the interceptor when the component unmounts
    return () => axios.interceptors.response.eject(interceptor);
  }, [navigate]);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setIsLoading(true);
        const authStatus = await isAuthenticated();
        if (authStatus) {
          // console.log("Authenticated, setting isLoggedIn to true");
          const storedUsername = localStorage.getItem('username');
          setIsLoggedIn(true);
          setUsername(storedUsername);
        } else {
          // Only clear local storage and redirect if not on a public route
          // console.log("Not authenticated, setting isLoggedIn to false");
          if (!isPublicRoute) {
            // console.log("Logging out");
            // handleLogout();
          } else {
            // console.log("Not authenticated, but on a public route, setting isLoggedIn to false");
            setIsLoggedIn(false);
            setUsername('');
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Authentication check failed:', error);
        handleLogout();
      }
    };
    checkAuth();
  }, []);

  const handleLoginSuccess = () => {
    const storedUsername = localStorage.getItem('username');
    setIsLoggedIn(true);
    setUsername(storedUsername);
  };

  const handleLogout = () => {
    logout(); // Use the logout function from auth.js
    setIsLoggedIn(false);
    setUsername('');
    navigate('/console');
  };

  const isPublicRoute = ["/", "/privacy-policy", "/signup", "/console"].includes(location.pathname);

  return (
    <div className="relative min-h-screen flex flex-col">
      {(!isLoading && isLoggedIn && !isPublicRoute) && (
        <div className="bg-gray-900 p-2 flex justify-end items-center space-x-4">
          <Link
            to="/console/dashboard"
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          >
            Dashboard
          </Link>
          <Logout onLogout={handleLogout} username={username} />
        </div>
      )}
      <div className="flex-grow">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/signup" element={<SignUp />} />

          <Route path="/console" element={<Login onLoginSuccess={handleLoginSuccess} />} />

          <Route path="/console/*" element={
            <AuthenticatedRoute>
              <Routes>
                <Route path="dashboard" element={<Dashboard isLoggedIn={isLoggedIn} />} />
                <Route path="manage-subusers" element={<ManageSubusers isLoggedIn={isLoggedIn} />} />
                <Route path="*" element={<Navigate to="/console/dashboard" replace />} />
              </Routes>
            </AuthenticatedRoute>
          } />
        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router basename="/">
      <AppContent />
    </Router>
  );
}

export default App;