// Function to get the token from local storage
function getToken() {
  return localStorage.getItem('token');
}

// Function to check token validity with the server
async function checkTokenWithServer() {
  const token = getToken();
  if (!token) {
    return false;
  }

  const apiBaseUrl = process.env.REACT_APP_NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_API_URL;


  try {
    const response = await fetch(`${apiBaseUrl}/users/check-auth`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (response.ok) {
      return true;
    } else if (response.status === 401 && !response.data.isValid) {
      // Token is invalid or expired
      console.log("Token is invalid or expired");
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      return false;
    }
  } catch (error) {
    console.error('Error checking token:', error);
  }

  return false;
}

// Function to check if the user is authenticated
export async function isAuthenticated() {
  return await checkTokenWithServer();
}

// Function to handle logout
export function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  // You can add any additional logout logic here
}