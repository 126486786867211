import React, { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import SalesforceCredentialsForm from './SalesforceCredentialsForm';
import { useNavigate } from 'react-router-dom';
import ManageSubUsers from './ManageSubUsers';
import { logout } from '../auth';
import { FaUserCog, FaLink, FaStripe, FaKey, FaRedo } from 'react-icons/fa';


function Dashboard({ isLoggedIn }) {
  const [hasSalesforceCredentials, setHasSalesforceCredentials] = useState(false);
  const [isConnectedToSalesforce, setIsConnectedToSalesforce] = useState(false);
  const [isConnectedToStripe, setIsConnectedToStripe] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showCredentialsForm, setShowCredentialsForm] = useState(false);
  const navigate = useNavigate();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);


  const apiBaseUrl = process.env.REACT_APP_NODE_ENV === 'development'
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL;

  const handleResetCredentials = () => {
    setShowCredentialsForm(true);
  };

  const handleCancelReset = () => {
    setShowCredentialsForm(false);
  };

  const handleCredentialsSaved = () => {
    setHasSalesforceCredentials(true);
    setShowCredentialsForm(false);
    checkConnections(); // Refresh connection status after saving credentials
  };

  useEffect(() => {
    if (isLoggedIn) {
      checkConnections();
    }
  }, [isLoggedIn]);

  function handleSalesforceLogin() {
    const token = localStorage.getItem('token'); // Get the token from local storage

    // fetch('https://unified-backend-38e9761596ff.herokuapp.com/salesforce/auth', {
    fetch(`${apiBaseUrl}/salesforce/auth`, {

      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      credentials: 'include',
    })
      .then(response => {
        if (response.ok && response.headers.get('content-type')?.includes('application/json')) {
          return response.json();
        }
        throw new Error('Non-JSON response received');
      })
      .then(data => {
        if (data.url) {
          // window.location.href = data.url;
          window.open(data.url, '_blank');
        } else {
          console.error('No URL provided for redirection');
        }
      })
      .catch(error => console.error('Error:', error));
  }



  function handleStripeLogin() {
    const token = localStorage.getItem('token');

    // fetch('https://unified-backend-38e9761596ff.herokuapp.com/stripe/connect', {
    fetch(`${apiBaseUrl}/stripe/connect`, {

      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      credentials: 'include',
    })
      .then(response => {
        if (response.ok && response.headers.get('content-type')?.includes('application/json')) {
          return response.json();
        }
        throw new Error('Non-JSON response received');
      })
      .then(data => {
        if (data.url) {
          // console.log(data.url);
          // window.location.href = data.url; // Perform the redirection
          window.open(data.url, '_blank');
        } else {
          console.error('No URL provided for redirection');
        }
      })
      .catch(error => console.error('Error:', error));
  }

  const checkConnections = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiBaseUrl}/users/connections`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setHasSalesforceCredentials(data.hasSalesforceCredentials);
      setIsConnectedToSalesforce(data.isConnectedToSalesforce);
      setIsConnectedToStripe(data.isConnectedToStripe);
      // console.log("data", data);
    } catch (error) {
      console.error('Error checking connections:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePassword = () => {
    setShowChangePasswordModal(true);
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <ClipLoader color="#ffffff" size={50} />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-4xl">
        <h2 className="text-2xl font-bold text-center text-white mb-6">
          {!hasSalesforceCredentials || showCredentialsForm ? "Set Up Salesforce Credentials" : "Connected Apps"}
        </h2>

        {(!hasSalesforceCredentials || showCredentialsForm) ? (
          <>
            <button
              onClick={handleCancelReset}
              className="mb-4 p-2 rounded bg-gray-500 text-white hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              Back to Dashboard
            </button>
            <SalesforceCredentialsForm onCredentialsSaved={handleCredentialsSaved} />
          </>
        ) : (
          <div className="grid grid-cols-2 gap-4">
            <DashboardButton
              icon={<FaUserCog className="mr-2" />}
              text="Manage Sub-Users"
              onClick={() => navigate('/console/manage-subusers')}
            />
            <DashboardButton
              icon={<FaLink className="mr-2" />}
              text={isConnectedToSalesforce ? "Reconnect Salesforce Account" : "Connect Salesforce Account"}
              onClick={handleSalesforceLogin}
            />
            <DashboardButton
              icon={<FaStripe className="mr-2" />}
              text={isConnectedToStripe ? "Reconnect Stripe Account" : "Connect Stripe Account"}
              onClick={handleStripeLogin}
            />
            <DashboardButton
              icon={<FaKey className="mr-2" />}
              text="Change Password"
              onClick={handleChangePassword}
            />
          </div>
        )}

        {hasSalesforceCredentials && !showCredentialsForm && (
          <DashboardButton
            icon={<FaRedo className="mr-2" />}
            text="Reset Salesforce Connected App Credentials"
            onClick={handleResetCredentials}
            className="mt-4 bg-red-500 hover:bg-red-400"
          />
        )}
      </div>
      {showChangePasswordModal && (
        <ChangePasswordModal onClose={() => setShowChangePasswordModal(false)} apiBaseUrl={apiBaseUrl} />
      )}
    </div>
  );
}

function DashboardButton({ icon, text, onClick, className = "" }) {
  return (
    <button
      onClick={onClick}
      className={`w-full p-3 rounded bg-indigo-500 text-white font-bold hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 flex items-center justify-center ${className}`}
    >
      {icon}
      {text}
    </button>
  );
}

function ChangePasswordModal({ onClose, apiBaseUrl }) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    if (newPassword !== confirmPassword) {
      setError("New passwords don't match");
      return;
    }
    try {
      const response = await fetch(`${apiBaseUrl}/users/change-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ oldPassword, newPassword }),
      });
      if (response.ok) {
        setSuccessMessage('Password changed successfully!');
        // Clear the form
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        // Close the modal after a short delay
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        const data = await response.json();
        setError(data.message || 'Failed to change password');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-md">
        <h3 className="text-2xl font-bold mb-4 text-white">Change Password</h3>
        {successMessage && (
          <p className="text-green-500 mb-4 text-center font-semibold">{successMessage}</p>
        )}
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            placeholder="Old Password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            className="w-full p-2 mb-4 border rounded bg-gray-700 text-white"
            required
          />
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="w-full p-2 mb-4 border rounded bg-gray-700 text-white"
            required
          />
          <input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full p-2 mb-4 border rounded bg-gray-700 text-white"
            required
          />
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 px-4 py-2 text-gray-300 bg-gray-600 rounded hover:bg-gray-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-400"
            >
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Dashboard;