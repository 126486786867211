import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-gray-900 text-gray-300 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-white mb-8">Terms &amp; Conditions</h1>
        
        <div className="space-y-6">
          <p>
            These terms and conditions applies to the DC Pay app (hereby referred to as "Application") for mobile devices that was created by DataColada Pty Ltd (hereby referred to as "Service Provider") as a Commercial service.
          </p>
          
          <p>
            Upon downloading or utilizing the Application, you are automatically agreeing to the following terms. It is strongly advised that you thoroughly read and understand these terms prior to using the Application. Unauthorized copying, modification of the Application, any part of the Application, or our trademarks is strictly prohibited. Any attempts to extract the source code of the Application, translate the Application into other languages, or create derivative versions are not permitted. All trademarks, copyrights, database rights, and other intellectual property rights related to the Application remain the property of the Service Provider.
          </p>
          
          <p>
            The Service Provider is dedicated to ensuring that the Application is as beneficial and efficient as possible. As such, they reserve the right to modify the Application or charge for their services at any time and for any reason. The Service Provider assures you that any charges for the Application or its services will be clearly communicated to you.
          </p>
          
          <p>
            The Application stores and processes personal data that you have provided to the Service Provider in order to provide the Service. It is your responsibility to maintain the security of your phone and access to the Application. The Service Provider strongly advise against jailbreaking or rooting your phone, which involves removing software restrictions and limitations imposed by the official operating system of your device. Such actions could expose your phone to malware, viruses, malicious programs, compromise your phone's security features, and may result in the Application not functioning correctly or at all.
          </p>
          
          <div className="mt-8">
            <p>
              Please note that the Application utilizes third-party services that have their own Terms and Conditions. Below are the links to the Terms and Conditions of the third-party service providers used by the Application:
            </p>
            <ul className="list-disc pl-5 mt-2">
              <li>
                <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer" className="text-indigo-400 hover:text-indigo-300">
                  Google Play Services
                </a>
              </li>
            </ul>
          </div>
          
          <p>
            Please be aware that the Service Provider does not assume responsibility for certain aspects. Some functions of the Application require an active internet connection, which can be Wi-Fi or provided by your mobile network provider. The Service Provider cannot be held responsible if the Application does not function at full capacity due to lack of access to Wi-Fi or if you have exhausted your data allowance.
          </p>
          
          <p>
            If you are using the application outside of a Wi-Fi area, please be aware that your mobile network provider's agreement terms still apply. Consequently, you may incur charges from your mobile provider for data usage during the connection to the application, or other third-party charges. By using the application, you accept responsibility for any such charges, including roaming data charges if you use the application outside of your home territory (i.e., region or country) without disabling data roaming. If you are not the bill payer for the device on which you are using the application, they assume that you have obtained permission from the bill payer.
          </p>
          
          <p>
            Similarly, the Service Provider cannot always assume responsibility for your usage of the application. For instance, it is your responsibility to ensure that your device remains charged. If your device runs out of battery and you are unable to access the Service, the Service Provider cannot be held responsible.
          </p>
          
          <p>
            In terms of the Service Provider's responsibility for your use of the application, it is important to note that while they strive to ensure that it is updated and accurate at all times, they do rely on third parties to provide information to them so that they can make it available to you. The Service Provider accepts no liability for any loss, direct or indirect, that you experience as a result of relying entirely on this functionality of the application.
          </p>
          
          <h2 className="text-2xl font-bold text-white mt-12 mb-4">Changes to These Terms and Conditions</h2>
          <p>
            The Service Provider may periodically update their Terms and Conditions. Therefore, you are advised to review this page regularly for any changes. The Service Provider will notify you of any changes by posting the new Terms and Conditions on this page.
          </p>
          <p className="mt-4">
            These terms and conditions are effective as of 2024-05-29
          </p>
          
          <h2 className="text-2xl font-bold text-white mt-12 mb-4">Contact Us</h2>
          <p>
            If you have any questions or suggestions about the Terms and Conditions, please do not hesitate to contact us at{' '}
            <a href="mailto:hello@datacolada.com" className="text-indigo-400 hover:text-indigo-300">
              hello@datacolada.com
            </a>.
          </p>
        </div>

        <div className="mt-12 space-y-6">
          <h2 className="text-3xl font-bold text-white mb-8">Data Collection and Use Policy for DC Pay "Tap & Go" Payment App</h2>
          <p>
            At DataColada, we prioritise the privacy and security of your personal information. This privacy policy outlines how we collect, use, and protect the data you submit when using DC Pay apps tap-and-go payment services.
          </p>
          <h3 className="text-2xl font-bold text-white mt-8 mb-4">1. Data Collection</h3>
          <p>
            We collect only the necessary personal information to facilitate smooth and secure payment transactions. This includes, but is not limited to, your name, contact details, and payment information. We may also collect technical data related to your device to enhance app performance and security.
          </p>
          <h3 className="text-2xl font-bold text-white mt-8 mb-4">2. Data Use</h3>
          <p>
            The information collected is used solely for processing payments, providing customer support, and improving our services. We do not sell, rent, or share your data with third parties for marketing purposes.
          </p>
          <h3 className="text-2xl font-bold text-white mt-8 mb-4">3. Data Protection</h3>
          <p>
            Your data is stored securely using industry-standard encryption and access controls. We implement appropriate measures to safeguard against unauthorised access, alteration, or disclosure of your information.
          </p>
          <h3 className="text-2xl font-bold text-white mt-8 mb-4">4. Data Retention</h3>
          <p>
            We retain your personal information only for as long as necessary to fulfill the purposes outlined in this policy, comply with legal obligations, and resolve disputes.
          </p>
          <h3 className="text-2xl font-bold text-white mt-8 mb-4">5. Your Rights</h3>
          <p>
            You have the right to access, update, or delete your personal information at any time. If you wish to exercise these rights or have any concerns regarding your data, please contact our support team.
          </p>
          <h3 className="text-2xl font-bold text-white mt-8 mb-4">6. Updates to the Policy</h3>
          <p>
            We may update this policy periodically to reflect changes in our practices or legal requirements. Any significant updates will be communicated through the app or via email.
          </p>
          <h3 className="text-2xl font-bold text-white mt-8 mb-4">7. Contact Information</h3>
          <p>
            For any questions or concerns about this policy, please reach out to us at{' '}
            <a href="mailto:hello@datacolada.com" className="text-indigo-400 hover:text-indigo-300">
              hello@datacolada.com
            </a>.
          </p>
        </div>
        
        <div className="mt-12 pt-8 border-t border-gray-700">
          <Link to="/" className="text-indigo-400 hover:text-indigo-300">
            &larr; Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;