import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';

function SignUp() {
    const navigate = useNavigate();

    const apiBaseUrl = process.env.REACT_APP_NODE_ENV === 'development'
        ? process.env.REACT_APP_API_URL_DEV
        : process.env.REACT_APP_API_URL;

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        color: '#65558F', // Default color
    });
    const [image, setImage] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiBaseUrl}/users/register`, formData, {
                withCredentials: true
            });
            const { token, username, imageURL, color, isNewUser } = response.data;

            if (image) {
                const imageFormData = new FormData();
                imageFormData.append('image', image);

                await axios.post(`${apiBaseUrl}/firebase/upload-image`, imageFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`
                    },
                    withCredentials: true
                });
            }

            localStorage.setItem('token', token);
            alert('Registration successful');
            navigate('/console/dashboard');
        } catch (error) {
            console.error('Registration error:', error);
            alert('Registration failed');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900">
            <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-2xl font-bold text-center text-white mb-6">Create an account</h2>
                <div className="text-center text-gray-400 mb-8">
                    <p className="text-lg mb-2">
                        <strong>Note:</strong> Sign-up is currently by invitation only.
                    </p>
                    <p>
                        Interested in joining DC Pay? Contact us at{' '}
                        <a href="mailto:hello@datacolada.com" className="text-indigo-400 hover:text-indigo-300">
                            hello@datacolada.com
                        </a>{' '}
                        to request an invitation.
                    </p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-400 mb-2" htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            placeholder="Username"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-400 mb-2" htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            placeholder="Email"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-400 mb-2" htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            placeholder="Password"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-400 mb-2" htmlFor="color">Choose a color</label>
                        <div className="flex items-center">
                            <input
                                type="color"
                                id="color"
                                name="color"
                                className="w-12 h-12 rounded mr-3"
                                value={formData.color}
                                onChange={handleChange}
                            />
                            <span className="text-white">{formData.color}</span>
                        </div>
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-400 mb-2" htmlFor="image">Profile Image</label>
                        <input
                            type="file"
                            id="image"
                            className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            onChange={handleImageChange}
                            accept="image/*"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full p-3 rounded bg-indigo-500 text-white font-bold hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                        Sign Up
                    </button>
                </form>
                <p className="text-center text-gray-400 mt-6">
                    Already have an account? <Link to="/console" className="text-indigo-400 hover:text-indigo-300">Sign in</Link>
                </p>
            </div>
        </div>
    );
}

export default SignUp;