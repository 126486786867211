import React from 'react';
import { useNavigate } from 'react-router-dom';

function Logout({ onLogout, username }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate('/console');
  };

  return (

    <button
      onClick={handleLogout}
      className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
    >
    <span className="mr-2">Logout {username}</span>
    </button>
  );
}

export default Logout;