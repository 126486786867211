import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isAuthenticated } from '../auth';

const AuthenticatedRoute = ({ children }) => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const authStatus = await isAuthenticated();
      setIsLoggedIn(authStatus);
    };
    checkAuth();
  }, []);

  if (!isLoggedIn) {
    return <Navigate to="/console" replace state={{ from: location }} />;
  }

  return children;
};

export default AuthenticatedRoute;